import {
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Select,
  Spin,
  Tabs,
  TabsProps,
  Tooltip,
  notification
} from "antd";
import React, { useEffect, useState } from "react";
import { RiEditLine } from "react-icons/ri";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import Sticky from "react-stickynode";
import { DCFInput } from "reia-dcf-client";
import { AssetDto, CalculationDetailDto } from "reia-rest-client";
import { getSingleProject } from "../../../../../app/features/Projects/project.slice";
import { getAssetByID } from "../../../../../app/features/assets/assets.slice";
import {
  getAssetDCFResult,
  getCalculationDetail,
  resetData,
  updateCalculationDetail,
} from "../../../../../app/features/calculation/calculationAsset.slice";
import { getAssetsByProject } from "../../../../../app/features/projectDetails/projectDetail.slice";
import IMAGES from "../../../../../assets/images";
import ConfirmationModal from "../../../../../components/Layout/ConfirmationModel/ConfirmationModal";
import {compareAssetValue, deepObjectAsStringCompare, getDCFInput} from "../../../../../utils/dcfHelper";
import {
  ASSETS_PAGE_SIZE,
  getDefaultNotifyToaster,
  PORTFOLIO_PAGE_SIZE,
} from "../../../../../utils/helpers";
import Calculation from "./Calculation";
import CashFlows from "./CashFlow";
import Dashboard from "./Dashboard";
import EditAsset from "./EditAsset";
import IncomeAndCost from "./IncomeAndCost";
import RentRoll from "./RentRoll";
import AssetDetailDrawer from "./component/AssetDetailDrawer";
import moment from "moment/moment";
import { getPortfolio } from "../../../../../app/features/Portfolio/portfolio.slice";
import {getSettingsByCategory} from "../../../../../app/features/settings/settings.slice";
import {getDurationInMonthsOrDefault} from "../../../../../utils/dcfParamsHelper";

export default function Index() {
  const [initialCalcDone, setInitialCalcDone] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(2);
  const [requestedTab, setRequestedTab] = useState(0);
  const [tabChangeBlocked, setTabChangeBlocked] = useState(false);
  const param = useParams();
  const ProjectId = param.projectId;
  const PortfolioId = param.portfolioId;
  // console.log(param, "param");
  // const assetId = param.assetDetailId;
  const [assetId, setAssetId] = useState(param.assetDetailId);
  const [dcfInputJson : DCFInput | undefined | null, setDCFInputJson] = useState();
  const { asset: assetData, isLoading, isSuccess }: { asset: AssetDto } = useSelector(
    (state) => state.assets
  );
  const [asset, setAsset] = useState(assetData ?? {})

  useEffect(() => { setAsset(assetData) }, [assetData])

  // console.log(asset, 'asset in index.jsx')
  const {
    project,
    isLoading: projectIsLoading,
    isSuccess: projectLoaded,
  } = useSelector((state) => state.projects);
  const projectDetails = useSelector((state) => state.projectDetails);
  // console.log(projectDetails?.assetsByProject?.content, "projectDetails");

  // ************Select Asset for details array *************
  const projectAssets = projectDetails?.assetsByProject?.content?.map(
    (item) => {
      return { label: `${item.id}-  ${item.assetName}`, value: item.id };
    }
  );
  const portfolioId = param.portfolioId;
  useEffect(() => {
    const finalData = {
      portfolioId: portfolioId,
      size: -1,
      page: 1
    };
    dispatch(getPortfolio(finalData));
  }, [portfolioId]);

  const { landTaxes, acquisitionCostsDefaults, assetTypesCapRatesDefaults, useTypesCostsDefaults } = useSelector(state => state.settings)
  
  // console.log(projectDetails);
  const {
    assetDCFParams,
    assetHVLParams,
    assetDCFResult,
    rentRollAssumptions,
    isCalculationDetailLoading,
    isAssetDCFCalcLoading,
    calculationDetail,
    calculationModified,
    kpisModified,
    assumptionsModified,
    assetValueMismatched,
    autoSaveCalc,
    assetKPIs
  } = useSelector((state) => state.calculation);
  const rentRolls = calculationDetail
    ? calculationDetail.rentRolls
      ? Object.values(calculationDetail.rentRolls)
      : []
    : null;
  const [showJsonEditor, setJsonEditor] = useState(false);
  // const notifyToaster = getDefaultNotifyToaster(notification);
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const shouldBlock = ({ currentLocation, nextLocation }) => {
    return (
      (calculationModified || assetValueMismatched ||kpisModified || assumptionsModified) &&
      currentLocation.pathname !== nextLocation.pathname
    );
  };

  const blocker = useBlocker(shouldBlock);
  const onTabChange = (activeKey) => {
    if (calculationModified || assetValueMismatched ||kpisModified || assumptionsModified) {
      setTabChangeBlocked(true);
      setRequestedTab(activeKey);
    } else {
      setActiveTab(activeKey);
      setTabChangeBlocked(false);
    }
  };

  //CLEANUP
  useEffect(
    () => () => {
      dispatch({ type: "RentRoll/clearData" });
      dispatch({ type: "calculation/clearData" });
    },
    []
  );

  //Init
  useEffect(() => {
    const finalData = {
      assetId: assetId,
    };
    dispatch(getAssetByID({ finalData, notifyToaster }));
    dispatch(
      getCalculationDetail({ finalData, notifyToaster, resetData: true })
    );
  }, [assetId]);

  useEffect(() => {
    if (assetDCFResult?.dcfResult) {
      setInitialCalcDone(true);
    }
  }, [assetDCFResult]);

  //load setting parameters
  useEffect(() => {
    dispatch(getSettingsByCategory({ finalData:{category: "landTaxes"} , notifyToaster }));
    dispatch(getSettingsByCategory({ finalData:{category: "acquisitionCostsDefaults"} , notifyToaster }));
    dispatch(getSettingsByCategory({ finalData:{category: "assetTypesCapRatesDefaults"} , notifyToaster }));
    dispatch(getSettingsByCategory({ finalData:{category: "useTypesCostsDefaults"} , notifyToaster }));
    
  }, []);
  
  //Update AreaIncome Data
  useEffect(() => {

    if (rentRolls && calculationDetail && assetDCFResult?.dcfResult)
      dispatch({
        type: "calculation/setDashboardDetails", payload: {
          rentRolls: rentRolls,
          analysisDate: moment(calculationDetail.analysisDate),
          dcfResult: assetDCFResult.dcfResult,
          asset: asset,
          useTypesCostsDefaults: useTypesCostsDefaults,
          assetTypesCapRatesDefaults: assetTypesCapRatesDefaults,
          assetType: calculationDetail.assetType,
          dcfParams: assetDCFParams
        }
      })
  //Update Cashflows
    if (asset && assetDCFResult?.dcfResult && rentRolls)
      dispatch({
        type: "calculation/setAssetCashflows", payload: {
          rentRolls: rentRolls,
          analysisDate: moment(calculationDetail.analysisDate),
          assetId: asset.id,
          durationInMonths: getDurationInMonthsOrDefault(assetDCFParams),
          dcfResult: assetDCFResult.dcfResult
        }
      })

  }, [asset, assetDCFParams?.durationInMonths, assetDCFResult?.dcfResult, calculationDetail]);

  //Update AssetKPIs
  useEffect(() => {
    if (asset && assetDCFResult?.dcfResult && rentRolls && assetTypesCapRatesDefaults && landTaxes && acquisitionCostsDefaults)
      dispatch({
        type: "calculation/setAssetKPIs", payload: {
          calculationDetail: calculationDetail,
          dcfResult: assetDCFResult.dcfResult,
          assetDCFParams: assetDCFParams,
          assetTypesCapRatesDefaults: assetTypesCapRatesDefaults,
          landTaxes: landTaxes, 
          acquisitionCostsDefaults: acquisitionCostsDefaults
          
        }
      })

  }, [assetDCFParams, assetDCFResult?.dcfResult, calculationDetail, assetTypesCapRatesDefaults, landTaxes, acquisitionCostsDefaults]);

  const allDataLoaded = () => {
    return asset != null && calculationDetail != null && assetDCFParams != null && landTaxes != null && acquisitionCostsDefaults != null && assetTypesCapRatesDefaults != null && useTypesCostsDefaults != null
  };

  const isCalcLoading = () => {
    return isAssetDCFCalcLoading || !initialCalcDone;
  };

  const saveCalculationDetails = (event, customAssetDCFResult) => {
    const calculationDetailUpdated: CalculationDetailDto = {
      id: calculationDetail.id,
      assetId: assetId,
      assetDCFParams: assetDCFParams,
      assetHVLParams: assetHVLParams,
      assetDCFResult: customAssetDCFResult ? customAssetDCFResult : assetDCFResult,
      assetKPIs: assetKPIs,
      rentRollsAssumptions: rentRollAssumptions
    };
    const finalData = {
      calculationDetailId: calculationDetailUpdated.id,
      calculationDetail: calculationDetailUpdated,
    };
    dispatch(updateCalculationDetail({ finalData, notifyToaster }));
  };

  //Whenever calculation is necessary
  useEffect(() => {
    startDcfCalc(getDcfInput());
  }, [
    asset,
    calculationDetail,
    assetDCFParams,
    assetHVLParams,
    rentRollAssumptions,
    assetDCFResult?.dcfResult
  ]);
  
  const getDcfInput = (): DCFInput => {
    let dcfInput: DCFInput = undefined;
    
    if (allDataLoaded() && assetDCFParams && assetHVLParams) {
      dcfInput = getDCFInput(asset.id, asset.analysisDate, assetDCFParams, assetHVLParams, rentRolls, rentRollAssumptions, calculationDetail.indexValuesAnalyseDate, assetDCFResult?.dcfResult, landTaxes, acquisitionCostsDefaults, calculationDetail.federalState, assetTypesCapRatesDefaults, useTypesCostsDefaults, calculationDetail.assetType);
    }
    
    return dcfInput;
  };
  
  const startDcfCalc = (dcfInput: DCFInput) => {
    if (dcfInput) {
      if(!deepObjectAsStringCompare(dcfInput, dcfInputJson))
      {
        setDCFInputJson(dcfInput)
        dispatch(getAssetDCFResult({ finalData: dcfInput, notifyToaster })).then(action => {
          if (autoSaveCalc) {
            saveCalculationDetails(null, action.payload)
          }
        });
      }
    }
  };

  const resetCalculationDetail = () => {
    dispatch(resetData());
  };

  //TODO: set page and size
  useEffect(() => {
    const finalData = {
      projectId: ProjectId,
    };

    dispatch(getSingleProject({ finalData }));
    loadAssetsByProject();
  }, [dispatch]);

  const loadAssetsByProject = () => {
    const finalData = {
      projectId: ProjectId,
      page: 1,
      size: ASSETS_PAGE_SIZE,
      portfolioId: PortfolioId,
    };
    dispatch(getAssetsByProject({ finalData }));
  };
  // ************

  // ************
  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <Sticky enabled={true} top={60} innerZ={10} innerActiveClass="stickyTabs">
      <div className="statisticsArea">
        <DefaultTabBar {...props} />
      </div>
    </Sticky>
  );

  const confirmUnsavedLeave = () => {
    if (blocker.state === "blocked") {
      blocker.proceed();
    } else {
      setTabChangeBlocked(false);
      setActiveTab(requestedTab);
      setRequestedTab(0);
    }
    resetCalculationDetail();
  };

  const cancelUnsavedLeave = () => {
    if (blocker.state === "blocked") {
      blocker.reset();
    } else {
      setTabChangeBlocked(false);
      setRequestedTab(0);
    }
  };

  const handleToggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const updateUrlParam = (newParam) => {
    const pathSegments = location.pathname.split('/');
    pathSegments[4] = newParam;
    const newPathname = pathSegments.join('/');
    navigate(newPathname);
  };

  const AssetSelect = () => {
    return <>{
      projectAssets?.length > 0 && (
        <div className="assets-sidebar__tabs">
          <Tooltip title={`move to previos asset`} color="#00215b" key="previous-asset">
            <div>
              <Button
                style={{ color: "#6F7173", minWidth: '27px', borderRadius: '50%' }}
                shape="circle"
                icon={<LeftOutlined />}
                size="small"
                onClick={() => {
                  const currentIndex = projectAssets.findIndex(
                    (option) => option.value === Number(assetId)
                  );
                  if (currentIndex > 0) {
                    const previousAssetId =
                      projectAssets[currentIndex - 1].value;
                    setAssetId(previousAssetId);
                    updateUrlParam(previousAssetId)
                  }
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title={`change asset`} color="#00215b" key="select-asset">
            <div>
              <Select
                placeholder="Select asset"
                optionFilterProp="children"
                className="custom-select"
                onChange={(value) => { setAssetId(value); updateUrlParam(value) }}
                options={projectAssets}
                value={
                  projectAssets.find((option) => option?.value === Number(assetId))
                    ?.label
                }
                defaultValue={
                  projectAssets.find((option) => option?.value === Number(assetId))
                    ?.label
                }
                style={{
                  width: "190px",
                  // maxWidth: "180px",
                  "& .ant-select-selector": {
                    border: "1px solid #00215b !important",
                  },
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title={`move to next asset`} color="#00215b" key="next-asset">
            <div>
              <Button
                style={{ color: "#6F7173", minWidth: '27px', borderRadius: '50%' }}
                shape="circle"
                size="small"
                icon={<RightOutlined />}
                onClick={() => {
                  const currentIndex = projectAssets.findIndex(
                    (option) => option.value === Number(assetId)
                  );
                  if (currentIndex < projectAssets.length - 1) {
                    const nextAssetId = projectAssets[currentIndex + 1].value;
                    setAssetId(nextAssetId);
                    updateUrlParam(nextAssetId)
                  }
                }}
              />
            </div>
          </Tooltip>
        </div>
      )
    }</>
  };

  const AssetDrawer = () => {
    return (
      <Col
        xs={24}
        md={showDrawer ? 8 : 0}
        lg={showDrawer ? 7 : 0}
        xl={showDrawer ? 5 : 0}
      // style={{ paddingRight: "22px" }}
      >
        {/* {showDrawer && (
          <Col
            xs={24}
            md={showDrawer ? 8 : 0}
            lg={showDrawer ? 7 : 0}
            xl={showDrawer ? 5 : 0}
            className="p-0 drawer-height mt-3 mt-md-0"
          > */}
        <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
          <AssetDetailDrawer
            assetData={asset}
            calculationDetail={calculationDetail}
            assetDCFParams={assetDCFParams}
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            projectAssets={projectAssets}
            assetId={assetId}
            setAssetId={setAssetId}
          />
        </Sticky>
        {/* </Col>
        )} */}
      </Col>
    )
  }
  return (
    <Row>
      <Col
        xs={24}
      // md={showDrawer ? 16 : 24}
      // lg={showDrawer ? 17 : 24}
      // xl={showDrawer ? 19 : 24}
      // style={{ paddingRight: "22px" }}
      >
        <Spin
          size="large"
          spinning={!(allDataLoaded() && !isCalculationDetailLoading)}
        >
          <div className="assetDetails">
            <ConfirmationModal
              showIcon={true}
              confirmMessage={"leave the Calculation unsaved?"}
              handleCancel={cancelUnsavedLeave}
              onSubmit={confirmUnsavedLeave}
              visible={blocker.state === "blocked" || tabChangeBlocked}
            />
            <div>
              {showJsonEditor ? (
                <div className="debug json">
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      DCF INPUT:
                      <JsonView
                        data={dcfInputJson}
                        shouldExpandNode={(e) => {
                          if (e === 0) return true;
                          if (e === 1) return true;
                          return false;
                        }}
                        style={defaultStyles}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      DCF RESULT:
                      <JsonView
                        data={assetDCFResult?.dcfResult}
                        shouldExpandNode={(e) => {
                          if (e === 0) return true;
                          if (e === 1) return true;
                          return false;
                        }}
                        style={defaultStyles}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              {allDataLoaded() ? (
                <Tabs
                  activeKey={"" + activeTab}
                  renderTabBar={renderTabBar}
                  onChange={onTabChange}
                >
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={IMAGES.ASSET_DASHBOARD_ICON} alt="" />
                        <p className="mb-0 ml-2">Asset Dashboard</p>
                      </div>
                    }
                    key="1"
                  >
                    <Dashboard AssetSelect={AssetSelect} assetData={assetData} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={IMAGES.CALCULATION} alt="" />
                        <p className="mb-0 ml-2">Calculation</p>
                      </div>
                    }
                    key="2"
                  >
                    <Row gutter={16}>
                      <Col
                        xs={24}
                        md={showDrawer ? 16 : 24}
                        lg={showDrawer ? 17 : 24}
                        xl={showDrawer ? 19 : 24}
                      // style={{ paddingRight: "22px" }}
                      >
                        <Calculation
                          showDrawer={showDrawer}
                          allRentRolls={rentRolls}
                          AssetSelect={AssetSelect}
                          calculationDetail={calculationDetail}
                          handleToggleDrawer={handleToggleDrawer}
                          autoSaveCalc={autoSaveCalc}
                          isCalcLoading={isCalcLoading}
                          allDataLoaded={allDataLoaded}
                          calculationModified={calculationModified}
                          kpisModified={kpisModified}
                          assumptionsModified={assumptionsModified}
                          assetValueMismatched={assetValueMismatched}
                          saveCalculationDetails={saveCalculationDetails}
                          resetCalculationDetail={resetCalculationDetail}
                        />
                      </Col>
                      <AssetDrawer />
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={IMAGES.ROLL} alt="" />
                        <p className="mb-0 ml-2">Rent Roll</p>
                      </div>
                    }
                    key="3"
                  >
                    <Row gutter={16}>
                      <Col
                        xs={24}
                        md={showDrawer ? 16 : 24}
                        lg={showDrawer ? 17 : 24}
                        xl={showDrawer ? 19 : 24}
                      // style={{ paddingRight: "22px" }}
                      >
                        <RentRoll handleToggleDrawer={handleToggleDrawer} showDrawer={showDrawer} AssetSelect={AssetSelect} />
                      </Col>
                      <AssetDrawer />
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        {/* <img src={IMAGES.PRICE} alt="" /> */}
                        <img src={IMAGES.ASSET_INCOME_COST_ICON} alt="" />
                        <p className="mb-0 ml-2">Other Income &amp; Cost</p>
                      </div>
                    }
                    key="4"
                  >
                    <Row gutter={16}>
                      <Col
                        xs={24}
                        md={showDrawer ? 16 : 24}
                        lg={showDrawer ? 17 : 24}
                        xl={showDrawer ? 19 : 24}
                      // style={{ paddingRight: "22px" }}
                      >

                        <IncomeAndCost
                          handleToggleDrawer={handleToggleDrawer}
                          showDrawer={showDrawer}
                          AssetSelect={AssetSelect}
                          saveCalculationDetails={saveCalculationDetails}
                          resetCalculationDetail={resetCalculationDetail}
                          autoSaveCalc={autoSaveCalc}
                          calculationDetail={calculationDetail}
                          isCalcLoading={isCalcLoading}
                          allDataLoaded={allDataLoaded}
                          calculationModified={calculationModified}
                          assetValueMismatched={assetValueMismatched}
                        />
                      </Col>
                      <AssetDrawer />
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={IMAGES.ASSET_CASH_FLOW_ICON} alt="" />
                        <p className="mb-0 ml-2 tabs-content">Cashflows</p>
                      </div>
                    }
                    key="5"
                  >
                    <Row gutter={16}>
                      <Col
                        xs={24}
                        md={showDrawer ? 16 : 24}
                        lg={showDrawer ? 17 : 24}
                        xl={showDrawer ? 19 : 24}
                      // style={{ paddingRight: "22px" }}
                      >

                        <CashFlows handleToggleDrawer={handleToggleDrawer} showDrawer={showDrawer} AssetSelect={AssetSelect} />
                      </Col>
                      <AssetDrawer />
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex justify-content-center align-items-center">
                        <RiEditLine size={24} style={{ color: 'currentcolor' }} />
                        <p className="mb-0 ml-2 tabs-content">Edit Assets</p>
                      </div>
                    }
                    key="6"
                  >
                    <Row gutter={16}>
                      <Col
                        xs={24}
                        md={showDrawer ? 16 : 24}
                        lg={showDrawer ? 17 : 24}
                        xl={showDrawer ? 19 : 24}
                      // style={{ paddingRight: "22px" }}
                      >

                        <EditAsset asset={asset} AssetSelect={AssetSelect} assetId={assetId} handleToggleDrawer={handleToggleDrawer} showDrawer={showDrawer} setAssetId={setAssetId} isLoading={isLoading} />
                      </Col>
                      <AssetDrawer />
                    </Row>
                  </Tabs.TabPane>
                  {/*<Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">*/}
                  {/*  <img src={IMAGES.ASSET_CALCULATION_SETTING_ICON} alt="" />*/}
                  {/*  <p className="mb-0 ml-2">Settings</p></div>} key="6">*/}
                  {/*  <CalculationSetting />*/}
                  {/*</Tabs.TabPane>*/}
                </Tabs>
              ) : (
                ""
              )}
            </div>
          </div>
        </Spin>
      </Col>
    </Row>
  );
}
